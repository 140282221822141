import { RouteObject, createBrowserRouter } from "react-router-dom";

import RootLayout from "./layouts/RootLayout.tsx";
import SignInPage from "./routes/SignInPage.tsx";
import SignUpPage from "./routes/SignUpPage.tsx";
import IndexPage from "./routes/index.tsx";

type CustomRouteConfig = RouteObject & { token?: string };

export const routerConfig: CustomRouteConfig[] = [
  {
    element: <RootLayout />,
    children: [
      {
        path: "*",
        element: <IndexPage />,
      },
      { path: "/sign-in/*", element: <SignInPage /> },
      { path: "/sign-up/*", element: <SignUpPage /> },
    ],
  },
];

export const router = createBrowserRouter(routerConfig);
