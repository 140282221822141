import { SignIn } from "@clerk/clerk-react";
import { useSearchParams } from "react-router-dom";

export default function SignInPage(): JSX.Element {
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirect_url");

  console.log(redirectUrl);

  return (
    <>
      <SignIn
        routing="path"
        path="/sign-in"
        signUpUrl="/sign-up"
        forceRedirectUrl={redirectUrl}
        appearance={{
          elements: {
            formButtonPrimary:
              "bg-gradient-to-b from-dvagFrom to-dvagTo border-dvag hover:bg-dvag/80 hover:border-dvag/80 focus:bg-dvag/80 focus:border-dvag/80 !shadow-none",
          },
        }}
      />
    </>
  );
}
