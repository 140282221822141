import { useClerk, useSignIn } from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";

import ClerkError, { ClerkErrorObject } from "../components/ClerkError";
import LoadingSpinner from "../components/LoadingSpinner";
import Page from "../components/Page";

export default function SignInTokenPage(): JSX.Element | null {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { signIn, setActive, isLoaded } = useSignIn();
  const { session } = useClerk();
  const [signingIn, setSigningIn] = useState(false);
  const [signInProcessed, setSignInProcessed] = useState(false);
  const [signInError, setSignInError] = useState<ClerkErrorObject | null>(null);
  const token = searchParams.get("token");
  const redirectUrl = searchParams.get("redirect_url");

  useEffect(() => {
    if (!isLoaded) {
      return;
    }

    if (token) {
      return;
    }

    navigate(
      {
        pathname: "/sign-in",
        search: redirectUrl
          ? createSearchParams({
              redirect_url: redirectUrl,
            }).toString()
          : "",
      },
      { replace: true },
    );
  }, [isLoaded, token, redirectUrl, navigate]);

  useEffect(() => {
    if (!signIn || !token || signingIn || signInProcessed || session) {
      return;
    }

    setSigningIn(true);
    setSignInProcessed(false);
    setSignInError(null);

    const createSignIn = async (): Promise<void> => {
      try {
        const res = await signIn.create({
          strategy: "ticket",
          ticket: token,
        });
        await setActive({
          session: res.createdSessionId,
        });
        setSigningIn(false);
        setSignInProcessed(true);
      } catch (err) {
        setSigningIn(false);
        setSignInProcessed(true);
        setSignInError(err as ClerkErrorObject);
      }
    };

    createSignIn();
  }, [signIn, setActive, token, signingIn, signInProcessed, session]);

  useEffect(() => {
    if (!signInProcessed || signInError) {
      return;
    }

    //setSearchParams({}, { replace: true });
    navigate("/", { replace: true });
  }, [navigate, setSearchParams, signInError, signInProcessed]);

  if (!isLoaded) {
    return <LoadingSpinner />;
  }

  if (signingIn) {
    return <LoadingSpinner />;
  }

  if (signInProcessed && signInError) {
    return (
      <Page>
        <ClerkError error={signInError} />
      </Page>
    );
  }

  return null;
}
