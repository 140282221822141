import { Link, LinkProps } from "react-router-dom";

export interface RedirectExternButtonProps
  extends Omit<LinkProps, "to" | "reloadDocument">,
    React.RefAttributes<HTMLAnchorElement> {
  redirectUrl: string;
  text?: string;
}

export function RedirectExternButton({
  redirectUrl,
  text,
  children,
  ...other
}: RedirectExternButtonProps): JSX.Element {
  return (
    <Link
      to={redirectUrl}
      reloadDocument
      replace
      rel="noopener noreferrer"
      {...other}
      className="flex items-center justify-center rounded-lg bg-gradient-to-b from-dvagFrom to-dvagTo pb-1 pl-10 pr-10 pt-1 text-center text-base font-medium text-white transition duration-500 ease-in-out hover:from-primary/80 hover:to-primary/80 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2"
    >
      {text || children}
    </Link>
  );
}

RedirectExternButton.displayName = "RedirectExternButton";
export default RedirectExternButton;
