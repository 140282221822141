export interface PageProps {
  children?: React.ReactNode;
}

export function Page({ children }: PageProps): JSX.Element {
  return (
    <div className="inline-block transform overflow-hidden rounded-lg bg-white align-bottom shadow-2xl transition-all sm:max-w-xl sm:align-middle">
      <div className="relative ml-auto mr-auto w-full items-center">
        <div className="grid grid-cols-1">
          <div className="mb-4 ml-auto mr-auto mt-4">
            <div className="flex flex-col items-center pb-6 pl-8 pr-8 pt-6">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

Page.displayName = "Page";
export default Page;
