import { useNavigate } from "react-router-dom";

export interface ClerkErrorObject {
  status: number;
  clerkError: boolean;
  errors: {
    code: string;
    message: string;
    meta: { [key: string]: object };
  }[];
}

export interface ClerkErrorProps {
  heading?: string;
  error: ClerkErrorObject;
}

export function ClerkError({ heading, error }: ClerkErrorProps): JSX.Element {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col items-center justify-center text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="h-12 w-12 text-red-600"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 8v4m0 4h.01M12 2a10 10 0 110 20 10 10 0 010-20z"
          />
        </svg>
        <h2 className="mb-4 mt-2 text-2xl font-bold text-red-600">{heading || "Etwas ist schiefgelaufen."}</h2>
        <div className="text-sm text-gray-700">
          <div>
            {error.errors.map((err, index: number) => (
              <div key={index} className="mt-1">
                <p className="text-gray-600">{err.message}</p>
              </div>
            ))}
          </div>
        </div>
        <button
          className="mt-6 w-full transform cursor-pointer rounded-lg bg-primary pb-1 pl-10 pr-10 pt-1 text-sm font-medium text-white transition duration-500 ease-in-out hover:bg-primary/80 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2"
          onClick={() => navigate("/", { replace: true })}
        >
          Zurück zur Startseite
        </button>
        <button
          className="mt-2 w-full transform cursor-pointer rounded-lg border-2 border-transparent pb-1 pl-10 pr-10 pt-1 text-sm font-medium text-primary transition duration-500 ease-in-out hover:border-primary/80 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2"
          onClick={() => window.location.reload()}
        >
          Support kontaktieren
        </button>
      </div>
    </>
  );
}

ClerkError.displayName = "ClerkError";
export default ClerkError;
