import {
  ErrorBoundary,
  init as initSentry,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
  wrapCreateBrowserRouter,
} from "@sentry/react";
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import "./windowConfiguration.ts";

import "./index.css";
import { routerConfig } from "./routes.tsx";

initSentry({
  enabled: process.env.NODE_ENV !== "development",
  dsn: import.meta.env.REACT_APP_SENTRY_DSN,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    replayIntegration(),
  ],

  tracesSampleRate: 1.0,

  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0,
});

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(routerConfig);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ErrorBoundary fallback={<p>An error has occurred</p>}>
      <RouterProvider router={router} />
    </ErrorBoundary>
  </React.StrictMode>,
);
