import { SignedIn, SignedOut } from "@clerk/clerk-react";

import SignInTokenPage from "./SignInTokenPage";
import SignedInPage from "./SignedInPage";

export default function IndexPage(): JSX.Element {
  return (
    <>
      <SignedIn>
        <SignedInPage />
      </SignedIn>
      <SignedOut>
        <SignInTokenPage />
      </SignedOut>
    </>
  );
}
