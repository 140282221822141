/**
 * File is used to add configuration values to window variable.
 * Needs to be imported before something wants to use window values.
 */

declare global {
    interface Window {
      REACT_APP_ENVIRONMENT: string;
      isSandbox: () => boolean;
      isDevelopement: () => boolean;
      isProduction: () => boolean;
      isStaging: () => boolean;
      isTest: () => boolean;
      isProductionLike: () => boolean;
    }
  }
  
  window.REACT_APP_ENVIRONMENT = window.REACT_APP_ENVIRONMENT || "";
  window.isSandbox = (): boolean => window.REACT_APP_ENVIRONMENT?.toLocaleLowerCase() === "sandbox";
  window.isDevelopement = (): boolean => window.REACT_APP_ENVIRONMENT?.toLocaleLowerCase() === "development";
  window.isProduction = (): boolean => window.REACT_APP_ENVIRONMENT?.toLocaleLowerCase() === "production";
  window.isStaging = (): boolean => window.REACT_APP_ENVIRONMENT?.toLocaleLowerCase() === "staging";
  window.isTest = (): boolean => window.REACT_APP_ENVIRONMENT?.toLocaleLowerCase() === "test";
  window.isProductionLike = (): boolean => window.isProduction() || window.isStaging();
  