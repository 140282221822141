import { SignUp } from "@clerk/clerk-react";
import { useSearchParams } from "react-router-dom";

export default function SignUpPage(): JSX.Element {
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirect_url");

  return (
    <>
      <SignUp
        routing="path"
        path="/sign-up"
        signInUrl="/sign-in"
        forceRedirectUrl={redirectUrl}
        appearance={{
          elements: {
            formButtonPrimary:
              "bg-gradient-to-b from-dvagFrom to-dvagTo border-dvag hover:bg-dvag/80 hover:border-dvag/80 focus:bg-dvag/80 focus:border-dvag/80 !shadow-none",
          },
        }}
      />
    </>
  );
}
