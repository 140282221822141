import { ClerkLoaded, ClerkLoading, ClerkProvider } from "@clerk/clerk-react";
import { deDE } from "@clerk/localizations";
import { Outlet, useNavigate } from "react-router-dom";

import LoadingSpinner from "../components/LoadingSpinner";

// Import your publishable key
const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;
if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

export default function RootLayout(): JSX.Element {
  const navigate = useNavigate();

  return (
    <>
      <ClerkProvider
        publishableKey={PUBLISHABLE_KEY}
        localization={deDE}
        telemetry={false}
        allowedRedirectOrigins={import.meta.env.VITE_CLERK_ALLOWED_REDIRECT_URLS.split(",")}
        routerPush={(to) => navigate(to)}
        routerReplace={(to) => navigate(to, { replace: true })}
      >
        <main className="flex min-h-screen items-center justify-center bg-gradient-to-b from-slate-100 to-slate-200 dark:from-zinc-800 dark:to-zinc-900">
          <ClerkLoading>
            <LoadingSpinner />
          </ClerkLoading>
          <ClerkLoaded>
            <Outlet />
          </ClerkLoaded>
        </main>
      </ClerkProvider>
    </>
  );
}
